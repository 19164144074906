import React, { Component } from 'react';
import Upload from '../Upload/index';
import Search from '../Library/components/Search/index';
import logo from '../../../../assets/vectorly-white.png';
import { Link, withRouter } from 'react-router-dom';
import { Pane, Button, Heading, Popover, Menu } from 'evergreen-ui';
import style from './style.css';

const CheckAuth = {
  isAuthenticated: localStorage.getItem('isAuthenticated'),
  switchTo(cb) {
    setTimeout(cb, 100);
  },
  signout(cb) {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    localStorage.removeItem('client');
    setTimeout(cb, 100);
  },
};

const DocsButton = withRouter(({ history }) => (
  <Button
    iconBefore="manual"
    className={style.docsLink}
    onClick={() => {
      window.open('https://vectorly.io/docs/', '_blank');
      return false;
    }}
  >
    Docs
  </Button>
));

const Settings = withRouter(({ history }) => (
  <Menu.Item
    icon="cog"
    onSelect={() => CheckAuth.switchTo(() => history.push('/main/settings'))}
  >
    Settings
  </Menu.Item>
));

const Profile = withRouter(({ history }) => (
  <Menu.Item
    icon="edit"
    onSelect={() => CheckAuth.switchTo(() => history.push('/main/profile'))}
  >
    Edit Profile
  </Menu.Item>
));

const Logout = withRouter(({ history }) => (
  <Menu.Item
    icon="log-out"
    onSelect={() => CheckAuth.signout(() => history.push('/login'))}
  >
    Logout
  </Menu.Item>
));

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      searchString: '',
    };
  }

  searchVideos = searchString => {
    if (this.state.openModal === true) this.setState({ openModal: false });
    this.props.callbackSearchMain(searchString);
  };

  reloadList = () => {
    if (this.state.openModal === true) this.setState({ openModal: false });
    this.props.callbackReloadList();
  }

  handleUploadModal = () => {
    this.props.resetReload();
    this.setState({ openModal: true });
  };

  render() {
    const { openModal, searchString } = this.state;

    return (
      <div className={style.style}>
        <Pane display="flex" padding={15}>
          <Pane marginTop={4} flex={1} display="flex">
            <Link to="/main" className={style.displayContents}>
              <img src={logo} alt="Logo" className={style.logo} />
              <Heading size={500} color="white" padding={6}>
                Vectorly
              </Heading>
            </Link>
          </Pane>
          <Pane marginTop={4} flex={5} display="flex">
            {this.props.hideUpload ? (
              ''
            ) : (
              <Button
                iconBefore="upload"
                onClick={this.handleUploadModal}
                className={style.uploadLink}
              >
                Upload
              </Button>
            )}
            <div className={style.searchMargin}>
              {this.props.hideSearch ? (
                ''
              ) : (
                <Search
                  searchString={searchString}
                  callbackSearch={this.searchVideos}
                />
              )}
            </div>
            <DocsButton />
            <Popover
              content={
                <Menu>
                  <Menu.Group>
                    {/* <Profile /> */}
                    <Settings />
                  </Menu.Group>
                  <Menu.Divider />
                  <Menu.Group>
                    <Logout />
                  </Menu.Group>
                </Menu>
              }
            >
              <Button
                iconBefore="user"
                className={style.profileLink}
              >
                Profile
              </Button>
            </Popover>
          </Pane>
        </Pane>
        <Upload openModal={openModal} reloadList={this.reloadList} />
      </div>
    );
  }
}

export default Navbar;
