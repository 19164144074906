import React, { Component } from 'react';
import Library from './components/Library/index';
import Navbar from './components/Navbar/index';
import axios from 'axios';
const API_URL = process.env.API_URL;

const getAccount = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/auth/account', {
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      reload: false,
    };
  }

  componentDidMount(){
    getAccount().then(response => {
      if(response.data) {
        localStorage.setItem('account', JSON.stringify(response.data));
      }
    });
  }

  searchVideosMain = searchString => {
    this.setState({ searchString: searchString });
  };

  resetReload = () => {
    this.setState({ reload: false });
  };

  reloadListMain = () => {
    this.setState({ reload: true });
  };

  render() {
    const { searchString, reload } = this.state;
    return (
      <div>
        <Navbar
          callbackSearchMain={this.searchVideosMain}
          callbackReloadList={this.reloadListMain}
          resetReload={this.resetReload}
        />
        <Library
          searchString={searchString}
          reload={reload}
        />
      </div>
    );
  }
}

export default Main;
