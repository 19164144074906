import axios from 'axios';
import React, { Component } from 'react';
const Uppy = require('@uppy/core');
const Tus = require('@uppy/tus');
const GoogleDrive = require('@uppy/google-drive');
const Dropbox = require('@uppy/dropbox');
const Url = require('@uppy/url');
const Webcam = require('@uppy/webcam');
const { DashboardModal } = require('@uppy/react');

const tusURL = process.env.TUS_URL;
const companionURL = process.env.COMPANION_URL;
const API_URL = process.env.API_URL;

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

export default class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      reset: false
    };

    this.uppy = new Uppy({
      id: 'uppy',
      autoProceed: true,
      debug: true,
      restrictions: {
        maxNumberOfFiles: 2000,
        minNumberOfFiles: 1,
        allowedFileTypes: ['video/*'],
      },
      onBeforeFileAdded: (currentFile, files) => {
        console.log('i am going to add file to dashboard', currentFile);
        if (!JSON.parse(localStorage.user).token || !JSON.parse(localStorage.client).id) {
          console.log('No auth token');
          return false;
        }
      },
      onBeforeUpload: files => {
        console.log('i am going to upload files', files);
        if (!JSON.parse(localStorage.user).token || !JSON.parse(localStorage.client).id) {
          console.log('No auth token');
          return false;
        }
        this.uppy.setMeta({
          token: JSON.parse(localStorage.user).token,
          client_id:
            JSON.parse(localStorage.client).id === 'self'
              ? JSON.parse(localStorage.user).username
              : JSON.parse(localStorage.client).id,
        });
        return true;
      },
    })
      .use(Tus, { endpoint: `${tusURL}/files/` })
      .use(GoogleDrive, { companionUrl: companionURL })
      .use(Dropbox, { companionUrl: companionURL })
      .use(Url, { companionUrl: companionURL })
      .use(Webcam, { companionUrl: companionURL })
      .on('upload', data => {
        console.log('uploading', data);
      })
      .on('file-added', file => {
        console.log('adding', file);
        this.uppy.setFileMeta(file.id, { uppy_meta: JSON.stringify(file) })
      })
      .on('file-removed', file => {
        console.log('removing', file);
        if (file.tus && file.tus.uploadUrl) {
          let params = file.tus.uploadUrl.split(`${tusURL}/files/`)[1]
          console.log('params', params);
          if(params) {
            axios.post(`${API_URL}/videos/tus-archive`, { 
              upload_id: params,
              token: JSON.parse(localStorage.user).token,
             }, function (response) {
              console.log('Server tus-archive requeste for', params, response);
            });
          }
        }
      })
      .on('upload-success', (file, response, uploadURL) => {
        console.log('upload successful, uploadUrl is', response.uploadURL);
        const uploadId = response.uploadURL.split(`${tusURL}/files/`)[1]
        console.log('checking', file, 'upload_id', uploadId);
        if(uploadId) {
          axios.post(`${API_URL}/videos/tus-missing`, { 
            upload_id: uploadId,
            token: JSON.parse(localStorage.user).token,
           }, function (response) {
            console.log('Server tus-check requeste for', uploadId, response);
          });
        }
      })
      .on('complete', result => {
        console.log('successful files:', result.successful);
        console.log('failed files:', result.failed);
        this.setState({reset: true});
      });

      this.handleClose = this.handleClose.bind(this);
  }

  handleClose () {
    this.props.openModal = false;
    this.setState({ open: this.props.openModal });
    if (this.state.reset === true) {
      this.uppy.reset()
      this.props.reloadList();
      this.setState({ reset: false });
    };
  }

  render() {
    return (
      <div>
        <DashboardModal
          uppy={this.uppy}
          open={this.props.openModal}
          target={document.body}
          onRequestClose={this.handleClose}
          plugins={['GoogleDrive', 'Dropbox']}
          metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
          locale={{
            strings: {
              addMore: 'Add more videos',
              dropPasteImport:
                'Drop videos here, paste, %{browse} or import from',
              youCanOnlyUploadFileTypes: 'You can only upload videos',
              complete: 'Upload complete. Return to Library',
            },
          }}
          width={750}
          height={550}
          thumbnailWidth={80}
          showProgressDetails={true}
          showLinkToFileUploadResult={false}
        />
      </div>
    );
  }
};
