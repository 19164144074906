import React, { Component } from 'react';
import Navbar from '../Navbar/index';
import {
  Pane,
  Heading,
  IconButton,
  UnorderedList,
  ListItem,
  toaster,
} from 'evergreen-ui';
import axios from 'axios';
import style from './style.css';
const API_URL = process.env.API_URL;

const getProfile = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/auth/account', {
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const updateProfile = value => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/auth/update', {
          data: { default_private: value },
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: null,
      defaultPrivate: false,
    };
  }

  componentDidMount() {
    getProfile().then(response => {
      this.setState({
        key: response.data.key,
        defaultPrivate: response.data.settings.default_private,
      });
    });
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  toggleDefaultPrivate = () => {
    let value = this.state.defaultPrivate;
    updateProfile(value).then(response => {
      console.log('response.data', response.data);
      toaster.notify('Account information updated successfully!', {
        duration: 1,
        id: `accountInfo`,
      });
    });
  };

  render() {
    return (
      <div>
        <Navbar hideUpload={true} hideSearch={true} />
        <div className={style.padding}>
          <IconButton icon="arrow-left" onClick={this.handleBack} />
          <Heading size={600} marginLeft={120} marginTop={-25}>
            Update Profile
          </Heading>
          <hr />
          <Pane className={style.paddingLeft}>
            <UnorderedList size={400}>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Email
                </Heading>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Contact Number
                </Heading>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Name
                </Heading>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Company
                </Heading>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Webiste
                </Heading>
              </ListItem>
            </UnorderedList>
          </Pane>
        </div>
      </div>
    );
  }
}

export default Profile;