import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Pane,
  Text,
  Checkbox,
  FormField,
  TextInputField,
  Button,
  Heading,
  Spinner,
  Paragraph,
} from 'evergreen-ui';
import axios from 'axios';
import style from './style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import logo from '../../../../assets/vectorly-full.svg';
import earth from '../../../../assets/earth-vector.png';
const API_URL = process.env.API_URL;

const signUp = values => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/auth/signup`, values)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        email: '',
        password: '',
        phone: '',
      },
      errors: {
        email: null,
        password: null,
        phone: null
      },
      isValid: false,
      isLoading: false,
      submitError: null,
      policy: false,
    };
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    // TODO : Add validations
    // const errors = validate(values, schema);

    // newState.errors = errors || {};
    // newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    if (field === 'policy') {
      this.setState({ policy: value });
    } else {
      const newState = { ...this.state };
      newState.submitError = null;
      newState.values[field] = value;

      this.setState(newState, this.validateForm);
    }
  };

  handlePhoneNumberChange(status, value, countryData, number, id) {
    this.handleFieldChange('phone', value);
  }

  handleSignup = async () => {
    try {
      const { history } = this.props;
      const { values, phone } = this.state;

      this.setState({ isLoading: true });

      const response = await signUp({
        username: values.email,
        email: values.email,
        password: values.password,
        phone: phone,
      });

      if (response && response.status === 200) {
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem(
          'client',
          JSON.stringify({
            individual: true,
            id: 'self',
            role: 'owner',
            name: response.data.username,
          }),
        );

        this.setState({ isLoading: false });
        history.push('/userinfo');
      } else {
        throw response;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error,
      });
    }
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { values, policy, submitError, isLoading } = this.state;
    values.policy = false;

    return (
      <div className={style.welcome}>
        <Pane padding={25}>
          <img src={logo} alt="Logo" className={style.logoStyle} />
          <Text className={style.loginText}>
            Already have an account? <Link to="/login" className={style.colorWhite}>Login</Link>
          </Text>
        </Pane>
        <img src={earth} className={style.bg_earth}></img>
        <div className={style.container}>
          <Pane clearfix>
            <Pane
              border
              paddingTop={16}
              paddingBottom={36}
              float="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="none"
            >
              <FormField className={style.formfield}>
                <Heading
                  marginBottom={24}
                  size={400}
                  marginTop={20}
                  marginLeft={40}
                >
                  Welcome! Signup to create a new account.
                </Heading>
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop={40}
                  marginLeft={40}
                  width={280}
                  label="Work Email"
                  value={values.email}
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                />
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop="default"
                  marginLeft={40}
                  type="password"
                  width={280}
                  label="Password"
                  value={values.password}
                  onChange={event =>
                    this.handleFieldChange('password', event.target.value)
                  }
                />
                <Text marginLeft={40} className={style.phone_text}>
                  Contact Number
                </Text>
                <Pane marginBottom={20} marginLeft={40}>
                  <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName={style.phone}
                    preferredCountries={['us', 'in', 'gb']}
                    onSelectFlag={(num, country) => {
                      console.log('onSelectFlag', num, country);
                    }}
                    onPhoneNumberChange={(
                      status,
                      value,
                      countryData,
                      number,
                      id,
                    ) => {
                      this.handlePhoneNumberChange(
                        status,
                        value,
                        countryData,
                        number,
                        id,
                      );
                    }}
                    placeholder=""
                    format
                    formatOnInit={false}
                    // separateDialCode
                  />
                </Pane>
                <Checkbox
                  marginLeft={40}
                  marginTop={24}
                  // label={this.renderPolicy}
                  checked={policy}
                  onChange={event =>
                    this.handleFieldChange('policy', event.target.checked)
                  }
                />
                <Paragraph className={style.textCenter}>
                  By signing up, you agree to our <br />
                  <a
                    data-ga-click="Footer, go to terms, text:terms"
                    href="https://vectorly.io/terms-of-service/"
                  >
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    data-ga-click="Footer, go to privacy, text:privacy"
                    href="https://vectorly.io/privacy/"
                  >
                    privacy policy.
                  </a>
                </Paragraph>
                <div className={style.error}>
                  {submitError ? <Text>{submitError}</Text> : ''}
                </div>
                <Button
                  marginTop="default"
                  marginLeft={40}
                  marginRight={16}
                  width={280}
                  alignItems="center"
                  justifyContent="center"
                  disabled={
                    !policy ||
                    values.email === '' ||
                    values.password === '' ||
                    values.phone === ''
                  }
                  onClick={this.handleSignup}
                >
                  {isLoading ? <Spinner size={16} /> : 'Signup'}
                </Button>
                <br />
              </FormField>
            </Pane>
          </Pane>
        </div>
      </div>
    );
  }
}

export default Signup;
