import React, { Component } from 'react';
import {  SearchInput } from 'evergreen-ui';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };
  }

  handleSearchString = (field, value) => {
    const newState = { ...this.state };
    newState[field] = value;
    this.setState(newState);
    this.props.callbackSearch(newState[field]);
  };

  render() {
    const { searchString } = this.state;

    return (
      <SearchInput
        width={400}
        placeholder="Search Videos..."
        onChange={event =>
          this.handleSearchString('searchString', event.target.value)
        }
        value={searchString}
      />
    );
  }
}

export default Search;
