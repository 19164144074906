import axios from 'axios';
import { Badge } from 'evergreen-ui';
const API_URL = process.env.API_URL;

const canPlay = video => {
    if (!video.status) {
      return false
    } else if (video.status === 'error' || video.status === 'uploading' || video.status === 'stalled') {
      return false
    } else {
      return true
    }
}


const updateVideo = (videoId, param_type, param_value, original_value, remove_common) => {
    
  return new Promise(resolve => {

    let temp = param_value;

    if (param_type === 'tag') { 
        param_value = { add: [], remove: []};
        // compare param_value and original_value arrays
        if (original_value.length > 0) {
          // add new values if original_value doesn't have
          param_value.add = temp.filter(x => !original_value.includes(x));
          // remove old values if temp doesn't have
          param_value.remove = original_value.filter(x => !temp.includes(x));
        } else {
          param_value.add = temp;
          if (remove_common && remove_common.length > 0) {
            param_value.remove = remove_common;
          }
        }
    }

    setTimeout(() => {
      axios
        .post(`${API_URL}/videos/update`, {
          video_id: videoId,
          param_type: param_type,
          param_value: param_value,
          token: JSON.parse(localStorage.getItem('user')).token,
          client_id: JSON.parse(localStorage.getItem('client')).id,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const privateVideo = (videoId, isVideoPrivate) => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/videos/private`, {
          video_id: videoId,
          is_private: isVideoPrivate,
          token: JSON.parse(localStorage.getItem('user')).token,
          client_id: JSON.parse(localStorage.getItem('client')).id,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const rebuildVideo = videoId => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/videos/rebuild`, {
          video_id: videoId,
          token: JSON.parse(localStorage.getItem('user')).token,
          client_id: JSON.parse(localStorage.getItem('client')).id,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const deleteVideo = videoId => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/videos/delete`, {
          video_id: videoId,
          token: JSON.parse(localStorage.getItem('user')).token,
          client_id: JSON.parse(localStorage.getItem('client')).id,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const copyEmbedCode = videoUrl => {
  let textField = document.createElement('textarea');
  textField.innerText = `<iframe src="${videoUrl}" width="800" height="515" frameborder="0" allowfullscreen  />`;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

const getReadableFileSizeString = fileSizeInBytes => {
  let i = -1;
  let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

const renderRowStatus = status => {
  switch (status) {
    case 'uploading':
      return <Badge color="orange">Uploading</Badge>;
    case 'uploaded':
      return <Badge color="yellow">Queued</Badge>;
    case 'stalled':
      return <Badge color="yellow">Upload Stalled</Badge>;
    case 'processing':
      return <Badge color="blue">Processing</Badge>;
    case 'ready':
      return <Badge color="green">Ready</Badge>;
    case 'optimizing':
      return <Badge color="green">Ready</Badge>;
    case 'error':
      return <Badge color="red">Errored</Badge>;
    default:
      return <Badge color="blue">In Progress</Badge>;
  }
};

export default {
    updateVideo: updateVideo,
    privateVideo: privateVideo,
    rebuildVideo: rebuildVideo,
    deleteVideo: deleteVideo,
    copyEmbedCode: copyEmbedCode,
    getReadableFileSizeString: getReadableFileSizeString,
    renderRowStatus: renderRowStatus,
    canPlay: canPlay,
}  
