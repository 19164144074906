import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

class Docs extends Component {
  render(){
    return (
      <div className={style['style']}>Docs</div>
    )
  }
}

Docs.propTypes = {
  title: PropTypes.string,
};

export default Docs;
