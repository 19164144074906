import React from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Main from './components/Main/index.jsx';
import Docs from './components/Main/components/Docs/index.jsx';
import Profile from './components/Main/components/Profile/index.jsx';
import Settings from './components/Main/components/Profile/components/Settings/index.jsx';
import Signup from './components/Auth/components/Signup/index';
import Login from './components/Auth/components/Login/index';
import Reset from './components/Auth/components/Reset/index';
import Verify from './components/Auth/components/Verify/index';
import UserInfo from './components/Auth/components/UserInfo/index';
import NoMatch from './components/404/index.jsx';

const browserHistory = createBrowserHistory();

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('isAuthenticated') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function ResetComp({match}) {
  if (match.params.token){
    localStorage.setItem('user', JSON.stringify({ token: match.params.token }));
    return <Route render={props => <Reset {...props} />} />;
  } else {
    return <Route render={props => <Verify {...props} />} />;
  }
}

function Routes() {
  return (
    <div>
      <Router history={browserHistory}>
        {location.hash.match('signup') !== null ? <Redirect from="/" to="/signup" /> : ''}
        {location.hash.match('verify') !== null ? <Redirect from="/" to="/verify" /> : ''}
        {location.hash.match('reset') === null && location.hash.match('signup') === null && location.hash.match('verify') === null ? <Redirect from="/" to="/main" /> : ''}
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/reset/:token" component={ResetComp} />
        <Route exact path="/userinfo" component={UserInfo} />
        <PrivateRoute exact path="/main" component={Main} />
        <PrivateRoute exact path="/main/docs" component={Docs} />
        <PrivateRoute exact path="/main/profile" component={Profile} />
        <PrivateRoute exact path="/main/settings" component={Settings} />
        <Route exact path="/404" component={NoMatch} />
      </Router>
    </div>
  );
}

export default Routes;
