import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Pane,
  Text,
  FormField,
  TextInputField,
  Button,
  Heading,
  Spinner,
} from 'evergreen-ui';
import axios from 'axios';
import style from './style.css';
import logo from '../../../../assets/vectorly-full.svg';
import earth from '../../../../assets/earth-vector.png';
const API_URL = process.env.API_URL;

const signIn = values => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/auth/login`, values)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class Login extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: null,
        password: null,
      },
      isValid: false,
      isLoading: false,
      submitError: null,
    };
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };

    // TODO Add validations
    // const errors = validate(values, schema);

    // newState.errors = errors || {};
    // newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSignin = async () => {
    try {
      
      const { history } = this.props;
      const { values } = this.state;

      this.setState({ isLoading: true });

      const response = await signIn({
        username: values.email,
        email: values.email,
        password: values.password,
      });

      if (response && response.status === 200) {
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem(
          'client',
          JSON.stringify({
            individual: true,
            id: 'self',
            role: 'owner',
            name: response.data.username,
          }),
        );
        this.setState({ isLoading: false });
        history.push('/main');
      } else {
        throw response;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error,
      });
    }
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { values, submitError, isLoading } = this.state;
    
    return (
      <div className={style.welcome}>
        <Pane padding={25}>
          <img src={logo} alt="Logo" className={style.logoStyle} />
          <Text className={style.askSignup}>
            Don't have an account? <Link to="/signup" className={style.colorWhite}>Signup</Link>
          </Text>
        </Pane>
        <img src={earth} className={style.bg_earth}></img>
        <div className={style.container}>
          <Pane clearfix>
            <Pane
              paddingTop={36}
              paddingBottom={36}
              float="center"
              display="flex"
              justifyContent="center"
              border="none"
            >
              <FormField className={style.formfield}>
                  <Heading
                    marginBottom={24}
                    size={400}
                    marginTop={40}
                    marginLeft={70}
                  >
                    Welcome! Log in to your account.
                  </Heading>
                  <TextInputField
                    height={24}
                    marginRight={36}
                    marginBottom={48}
                    marginTop={40}
                    marginLeft={40}
                    width={280}
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={event =>
                      this.handleFieldChange('email', event.target.value)
                    }
                  />
                  <TextInputField
                    height={24}
                    marginRight={36}
                    marginBottom={48}
                    marginTop="default"
                    marginLeft={40}
                    type="password"
                    width={280}
                    label="Password"
                    value={values.password}
                    onChange={event =>
                      this.handleFieldChange('password', event.target.value)
                    }
                  />
                  <div className={style.error}>
                    {submitError ? <Text> {submitError} </Text> : ''}
                  </div>
                  <Button
                    marginTop="default"
                    marginLeft={40}
                    marginRight={16}
                    width={280}
                    alignItems="center"
                    justifyContent="center"
                    onClick={this.handleSignin}
                    disabled={values.email === '' || values.password === ''}
                  >
                    {isLoading ? <Spinner size={16} /> : 'Login'}
                  </Button>
                  <div className={style.forgotPassword}>
                    <Text>
                      Forgot password? <Link to="/verify">Reset</Link>
                    </Text>
                  </div>
              </FormField>
            </Pane>
          </Pane>
        </div>
      </div>
    );
  }
}

export default Login;
