import React, { Component } from 'react';
import VideoActions from './VideoActions';
import { TableLoading } from './PlaceholderLoading';
import VideoRowBase from './VideoRowBase';
const jwt = require('jwt-simple');
import {
  Table,
  Position,
  SelectMenu,
  Button,
  Text,
  Textarea,
  IconButton,
  Icon,
  TagInput,
  Paragraph,
  Pane,
  Dialog,
  toaster,
  Tooltip,
} from 'evergreen-ui';
import style from './style.css';

const STREAM_URL = process.env.STREAM_URL;

const canPlay = VideoActions.canPlay

class VideoTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changedName: '',
      selectedVideoToEmbed: null,
      selectedVideoToPrivate: null,
      selectedVideoToDownload: null,
      selectedVideoToRename: null,
      selectedVideoToRebuild: null,
      selectedVideoToDelete: null,
      selectedVideoToPlay: null,
      selectedVideoToUpdate: null,
      addedTags: [],
      removedTags: [],
      selectedVideoToTag: null,
      selectedTag: [],
      selectedTagNames: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      JSON.stringify(this.props) !== JSON.stringify(nextProps)
    );
  };

  handleChange(event) {
    this.setState({ changedName: event.target.value });
  }

  updateVideoList = (videoId, task) => {
    let indexToUpdate;

    this.props.videos.forEach((video, index) => {
      if (video.id === videoId) indexToUpdate = index;
    });

    switch (task) {
      case 'delete':
        this.props.videos.splice(indexToUpdate, 1);
        break;
      case 'rename':
        this.props.videos[indexToUpdate].name = this.state.changedName;
        break;
      case 'updateTags':
        this.props.videos[indexToUpdate].tags = this.state.selectedTag;
        break;
      case 'rebuild':
        this.props.videos[indexToUpdate].status = 'processing';
        break;
      case 'private':
        this.props.videos[indexToUpdate].private = !this.props.videos[indexToUpdate].private;
        break;
      default:
        break;
    }
  };

  renderPlay = () => {
    let { selectedVideoToPlay } = this.state;
    if (selectedVideoToPlay === null) {
      return null;
    } else {
      const videoUrl = selectedVideoToPlay.private
        ? `${STREAM_URL}/embed/video/${selectedVideoToPlay.id}/token/${jwt.encode({ video_id: selectedVideoToPlay.id, expiry: Date.now() + 30 * 24 * 60 * 60 * 1000 }, JSON.parse(localStorage.account).key)}`
        : `${STREAM_URL}/embed/video/${selectedVideoToPlay.id}`;
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            width={830}
            hasHeader={false}
            hasFooter={false}
            isShown={
              selectedVideoToPlay !== null &&
              canPlay(selectedVideoToPlay)
            }
            onCloseComplete={() => this.setState({ selectedVideoToPlay: null })}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
          >
            <Paragraph>
              <iframe
                src={videoUrl}
                width="800"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  renderPrivate = () => {
    let { selectedVideoToPrivate } = this.state;
    if (selectedVideoToPrivate === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToPrivate !== null}
            title="Update video privacy settings"
            intent="success"
            onCloseComplete={() =>
              this.setState({ selectedVideoToPrivate: null })
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel={
              selectedVideoToPrivate.private
                ? 'Set as Public'
                : 'Set as Private'
            }
            onConfirm={() => {
              VideoActions.privateVideo(
                selectedVideoToPrivate.id,
                String(!selectedVideoToPrivate.private),
              );
              this.updateVideoList(selectedVideoToPrivate.id, 'private');
              toaster.notify('Video privacy changed successfully', {
                duration: 1,
                id: `privacy-code-${selectedVideoToPrivate.id}`,
              });
              this.setState({ selectedVideoToPrivate: null, selectedVideoToUpdate: null });
            }}
            onCancel={() => this.setState({ selectedVideoToPrivate: null })}
          >
            {selectedVideoToPrivate.private ? (
              <Paragraph>
                I understand that setting a video as public will allow any
                unauthorised access to the video.
              </Paragraph>
            ) : (
              <Paragraph>
                I understand that setting a video as private will not allow any
                unauthorised access to the video.
              </Paragraph>
            )}
          </Dialog>
        </Pane>
      );
    }
  };

  renderDownload = () => {
    let { selectedVideoToDownload } = this.state;
    if (selectedVideoToDownload === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToDownload !== null}
            title="Download video"
            hasHeader={false}
            intent="success"
            onCloseComplete={() =>
              this.setState({ selectedVideoToDownload: null })
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Download"
            onConfirm={() => {
              toaster.notify('Video downloading in progress...', {
                duration: 1,
                id: `download-code-${selectedVideoToDownload}`,
              });
              const downloadUrl = `${STREAM_URL}/file/v1/video/${selectedVideoToDownload.id}/token/${jwt.encode({ video_id: selectedVideoToDownload.id, expiry: Date.now() + 30 * 24 * 60 * 60 * 1000 }, JSON.parse(localStorage.account).key)}/download`;
              // selectedVideoToDownload.private : ? `${STREAM_URL}/file/v1/video/${selectedVideoToDownload.id}/download`;
              let a = document.createElement('a');
              a.href = downloadUrl;
              a.click();
              this.setState({ selectedVideoToDownload: null, selectedVideoToUpdate: null });
            }}
          >
            <Paragraph>
              <b>Download video</b>
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  renderTag = () => {
    let { selectedVideoToTag } = this.state;
    if (selectedVideoToTag === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToTag !== null}
            title="Update Video Tags"
            intent="success"
            onCloseComplete={() => this.setState({ selectedVideoToTag: null })}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Save Changes"
            onConfirm={() => {
              VideoActions.updateVideo(
                selectedVideoToTag.id,
                'tag',
                this.state.selectedTag,
                selectedVideoToTag.tags,
              );
              this.updateVideoList(selectedVideoToTag.id, 'updateTags');
              toaster.notify('Video tags updated successfully!', {
                duration: 1,
                id: `tag-code-${selectedVideoToTag.id}`,
              });
              this.setState({ selectedVideoToTag: null, selectedVideoToUpdate: null });
            }}
            isConfirmDisabled={
              (this.state.addedTags && this.state.addedTags.length === 0) &&
              (this.state.removedTags && this.state.removedTags.length === 0)
            }
          >
            <Pane>
              <SelectMenu
                isMultiSelect
                title="Select tags"
                titleView={({ title, close }) => {
                  return (
                    <Pane
                      display="flex"
                      alignItems="center"
                      borderBottom="default"
                      padding={4}
                      boxSizing="border-box"
                    >
                      <Pane flex="1" display="flex" alignItems="center">
                        <Text size={400}>{title}</Text>
                        <Tooltip
                          content="Select tags to add"
                          position={Position.BOTTOM}
                        >
                          <Icon
                            size={12}
                            marginLeft={4}
                            color="rgb(66, 90, 112)"
                            icon="help"
                          />
                        </Tooltip>
                        <Tooltip
                          content="Deselect all tags"
                          position={Position.BOTTOM}
                        >
                          <Button
                            appearance="minimal"
                            // iconBefore="remove"
                            color="rgb(66, 90, 112)"
                            marginLeft={24}
                            height={28}
                            onClick={() => {
                              this.setState({
                                addedTags: [],
                                removedTags: this.state.selectedTag,
                                selectedTag: [],
                                selectedTagNames: '',
                              });
                            }}
                          >
                            Deselect all
                          </Button>
                        </Tooltip>
                      </Pane>
                      <Tooltip content="Close" position={Position.BOTTOM}>
                        <IconButton
                          icon="cross"
                          appearance="minimal"
                          height={24}
                          onClick={close}
                        />
                      </Tooltip>
                    </Pane>
                  );
                }}
                options={this.props.tags.map(label => ({
                  label,
                  value: label,
                }))}
                selected={this.state.selectedTag}
                onSelect={item => {
                  const selectedTag = [...this.state.selectedTag, item.value];
                  const selectedTagItems = selectedTag;
                  const selectedTagItemsLength = selectedTagItems.length;
                  let selectedTagNames = '';
                  if (selectedTagItemsLength === 0) {
                    selectedTagNames = '';
                  } else if (selectedTagItemsLength === 1) {
                    selectedTagNames = selectedTagItems.toString();
                  } else if (selectedTagItemsLength > 1) {
                    selectedTagNames =
                      selectedTagItemsLength.toString() + ' added';
                  }
                  let addedTags = [...this.state.addedTags];
                  if (addedTags.indexOf(item.value) === -1) addedTags.push(item.value);
                  let removedTags = [...this.state.removedTags];
                  if (removedTags.indexOf(item.value) !== -1) removedTags.splice(removedTags.indexOf(item.value));
                  this.setState({
                    addedTags: addedTags,
                    removedTags: removedTags,
                    selectedTag,
                    selectedTagNames,
                  });
                }}
                onDeselect={item => {
                  const deselectedTagItemIndex = this.state.selectedTag.indexOf(
                    item.value,
                  );
                  const selectedTagItems = this.state.selectedTag.filter(
                    (_item, i) => i !== deselectedTagItemIndex,
                  );
                  const selectedTagItemsLength = selectedTagItems.length;
                  let selectedTagNames = '';
                  if (selectedTagItemsLength === 0) {
                    selectedTagNames = '';
                  } else if (selectedTagItemsLength === 1) {
                    selectedTagNames = selectedTagItems.toString();
                  } else if (selectedTagItemsLength > 1) {
                    selectedTagNames =
                      selectedTagItemsLength.toString() + ' added';
                  }
                  let addedTags = [...this.state.addedTags];
                  if (addedTags.indexOf(item.value) !== -1) addedTags.splice(addedTags.indexOf(item.value));
                  let removedTags = [...this.state.removedTags];
                  if (removedTags.indexOf(item.value) === -1) removedTags.push(item.value);
                  this.setState({
                    addedTags: addedTags,
                    removedTags: removedTags,
                    selectedTag: selectedTagItems,
                    selectedTagNames,
                  });
                }}
              >
                <Button iconAfter="caret-down">
                  Tags
                  {/* {this.state.selectedTagNames ? `: ${this.state.selectedTagNames}`: ''} */}
                </Button>
              </SelectMenu>
              <TagInput
                width="80%"
                float="right"
                tagProps={value => {
                  // if (this.state.selectedTag.filter(tag => (this.state.addedTags.includes(tag) && tag === value)).length > 0)
                  //   return { color: 'red', isSolid: true };
                  return {};
                }}
                inputProps={{ placeholder: 'Type to add a tag' }}
                values={this.state.selectedTag}
                onAdd={addedTags => {
                  if (addedTags && addedTags.length !== 0) this.setState({ addedTags: addedTags });
                  if (addedTags && addedTags.length !== 0 && this.state.selectedTag.indexOf(addedTags[0]) === -1) {
                    let selectedTag = [...this.state.selectedTag];
                    selectedTag.push(addedTags[0]);
                    this.setState({ selectedTag: selectedTag });
                  }
                }}
                onRemove={removedTags => {
                  if (removedTags && removedTags.length !== 0) this.setState({ removedTags: removedTags });
                  if (removedTags && removedTags.length !== 0 && this.state.selectedTag.indexOf(removedTags) !== -1) {
                    let selectedTag = [...this.state.selectedTag];
                    selectedTag.splice(selectedTag.indexOf(removedTags));
                    this.setState({ selectedTag: selectedTag });
                  }
                }}
                onChange={selectedTag => {
                  // if (this.state.selectedTag.indexOf(selectedTag[selectedTag.length-1]) === -1) this.setState({ selectedTag: selectedTag });
                }}
              />
            </Pane>
          </Dialog>
        </Pane>
      );
    }
  };

  renderRename = () => {
    let { selectedVideoToRename, changedName } = this.state;
    if (selectedVideoToRename === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToRename !== null}
            title="Edit Video Name"
            intent="success"
            onCloseComplete={() =>
              this.setState({ selectedVideoToRename: null })
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Save Changes"
            onConfirm={() => {
              VideoActions.updateVideo(
                selectedVideoToRename,
                'name',
                changedName,
              );
              this.updateVideoList(selectedVideoToRename, 'rename');
              toaster.notify('Video renamed successfully', {
                duration: 1,
                id: `rename-code-${selectedVideoToRename}`,
              });
              this.setState({ selectedVideoToRename: null, selectedVideoToUpdate: null });
            }}
            isConfirmDisabled={changedName.length === 0}
          >
            <Paragraph>
              <Textarea value={changedName} onChange={this.handleChange} />
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  renderRebuild = () => {
    let { selectedVideoToRebuild } = this.state;
    if (selectedVideoToRebuild === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToRebuild !== null}
            title="Are you sure you want to rebuild this video?"
            intent="success"
            onCloseComplete={() =>
              this.setState({ selectedVideoToRebuild: null })
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Rebuild"
            onConfirm={() => {
              VideoActions.rebuildVideo(selectedVideoToRebuild);
              this.updateVideoList(selectedVideoToRebuild, 'rebuild');
              toaster.notify('Rebuilding Video...', {
                duration: 1,
                id: `rebuild-code-${selectedVideoToRebuild}`,
              });
              this.setState({ selectedVideoToRebuild: null, selectedVideoToUpdate: null });
            }}
          >
            <Paragraph>
              I understand that rebuilding a video from Vectorly is permanent
              going to remove earlier builds and cannot be undone.
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  renderDelete = () => {
    let { selectedVideoToDelete } = this.state;
    if (selectedVideoToDelete === null) {
      return null;
    } else {
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            isShown={selectedVideoToDelete !== null}
            title="Permanently delete this video?"
            intent="danger"
            onCloseComplete={() =>
              this.setState({ selectedVideoToDelete: null })
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Delete"
            onConfirm={() => {
              VideoActions.deleteVideo(selectedVideoToDelete);
              this.updateVideoList(selectedVideoToDelete, 'delete');
              toaster.notify('Video deleted', {
                duration: 1,
                id: `delete-code-${selectedVideoToDelete}`,
              });
              this.setState({ selectedVideoToDelete: null, selectedVideoToUpdate: null });
            }}
          >
            <Paragraph>
              I understand that deleting a video from Vectorly is permanent and
              cannot be undone.
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  renderEmbed = () => {
    let { selectedVideoToEmbed } = this.state;
    if (selectedVideoToEmbed === null) {
      return null;
    } else {
      const videoUrl = selectedVideoToEmbed.private
        ? `${STREAM_URL}/embed/video/${selectedVideoToEmbed.id}/token/${jwt.encode({ video_id: selectedVideoToEmbed.id, expiry: Date.now() + 30 * 24 * 60 * 60 * 1000 }, JSON.parse(localStorage.account).key)}`
        : `${STREAM_URL}/embed/video/${selectedVideoToEmbed.id}`;
      return (
        <Pane>
          <Dialog
            topOffset="24vmin"
            hasCancel={false}
            isShown={selectedVideoToEmbed !== null}
            title="Embed code"
            intent="success"
            onCloseComplete={() => this.setState({ selectedVideoToEmbed: null })}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEscapePress={true}
            confirmLabel="Copy Embed Code"
            onConfirm={() => {
              VideoActions.copyEmbedCode(videoUrl);
              toaster.notify('Embed code copied to clipboard', {
                duration: 1,
                id: `embed-code-${selectedVideoToEmbed}`,
              });
              this.setState({ selectedVideoToEmbed: null, selectedVideoToUpdate: null });
            }}
          >
            <Paragraph>
              <Textarea
                disabled={true}
                defaultValue={`<iframe src="${videoUrl}" width="800" height="515" frameborder="0" allowfullscreen  />`}
              />
            </Paragraph>
          </Dialog>
        </Pane>
      );
    }
  };

  callbackState = change => {
    this.setState(change);
  };

  renderRow = video => {
    const { selected, handleSelection } = this.props;
    return (
      <VideoRowBase
        key={video.id}
        video={video}
        isSelected={selected.length > 0 ? selected.indexOf(video.id) !== -1 : false}
        handleSelection={handleSelection}
        callbackState={this.callbackState}
        selectedVideoToUpdate={this.state.selectedVideoToUpdate}
      />
    );
  };

  render() {
    const { videos, isLoading } = this.props;
    let tableHeight = 650;
    if (videos.length === 0) {
      tableHeight = 65;
    } else {
      tableHeight = videos.length * 65;
    }
    if (isLoading) {
      return <TableLoading />;
    } else {
      return (
        <>
          {this.renderEmbed()}
          {this.renderPlay()}
          {this.renderPrivate()}
          {this.renderDownload()}
          {this.renderTag()}
          {this.renderRename()}
          {this.renderRebuild()}
          {this.renderDelete()}
          <Table.VirtualBody height={tableHeight} className={style.overflow}>
            {videos.length > 0 ? (
              videos.map(video => this.renderRow(video))
            ) : (
              <Table.Row>
                <Table.Cell justifyContent="center">
                  No videos found. Upload Now!
                </Table.Cell>
              </Table.Row>
            )}
          </Table.VirtualBody>
        </>
      );
    }
  }
}

export default VideoTable;
