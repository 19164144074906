import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Pane,
  Text,
  FormField,
  TextInputField,
  Button,
  Heading,
  Spinner,
} from 'evergreen-ui';
import axios from 'axios';
import style from './style.css';
import logo from '../../../../assets/vectorly-full.svg';
import earth from '../../../../assets/earth-vector.png';

const API_URL = process.env.API_URL;

const updateUserInfo = values => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/auth/update`, {
          properties: values,
          token: JSON.parse(localStorage.user).token,
          email: JSON.parse(localStorage.user).username,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class UserInfo extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        full_name: '',
        company: '',
        website: '',
        stack: '',
        why: '',
      },
      errors: {
        full_name: null,
        company: null,
        website: null,
        stack: null,
        why: null,
      },
      isValid: false,
      isLoading: false,
      submitError: null,
    };
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    // TODO Add validations
    // const errors = validate(values, schema);

    // newState.errors = errors || {};
    // newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleUserInfo = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;

      this.setState({ isLoading: true });

      let first_name, last_name;

      if (values.full_name && values.full_name.split(' ').length > 1) {
        first_name = values.full_name.split(' ')[0];
        last_name = values.full_name.split(' ')[values.full_name.split(' ').length - 1];
      }

      const response = await updateUserInfo({
          first_name: first_name,
          last_name: last_name,
          company: values.company,
          website: values.website,
          stack: values.stack,
          why: values.why,
          isCreatingOrg: '0',
      });

      if (response && response.status === 200) {
        history.push('/main');
      } else {
        throw response;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error,
      });
    }
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { values, submitError, isLoading } = this.state;

    return (
      <div className={style.welcome}>
        <Pane padding={25}>
          <img src={logo} alt="Logo" className={style.logoStyle} />
          <Text className={style.loginText}>
            Already have an account?{' '}
            <Link to="/login" className={style.colorWhite}>
              Login
            </Link>
          </Text>
        </Pane>
        <img src={earth} className={style.bg_earth}></img>
        <div className={style.container}>
          <Pane clearfix>
            <Pane
              border
              paddingTop={16}
              paddingBottom={36}
              float="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="none"
            >
              <FormField className={style.formfield}>
                <Heading
                  marginBottom={24}
                  size={400}
                  marginTop={40}
                  marginLeft={120}
                >
                  One last step...
                </Heading>
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop={40}
                  width={280}
                  marginLeft={40}
                  label="Name"
                  value={values.full_name}
                  onChange={event =>
                    this.handleFieldChange('full_name', event.target.value)
                  }
                />
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop="default"
                  width={280}
                  marginLeft={40}
                  label="Company Name"
                  value={values.company}
                  onChange={event =>
                    this.handleFieldChange('company', event.target.value)
                  }
                />
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop="default"
                  width={280}
                  marginLeft={40}
                  label="Company Website"
                  value={values.website}
                  onChange={event =>
                    this.handleFieldChange('website', event.target.value)
                  }
                />
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop="default"
                  width={280}
                  marginLeft={40}
                  label="How do you deliver videos to your users?"
                  value={values.stack}
                  onChange={event =>
                    this.handleFieldChange('stack', event.target.value)
                  }
                />
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop="default"
                  width={280}
                  marginLeft={40}
                  label="What brings you to Vectorly?"
                  value={values.why}
                  onChange={event =>
                    this.handleFieldChange('why', event.target.value)
                  }
                />
                <div className={style.error}>
                  {submitError ? <Text>{submitError}</Text> : ''}
                </div>
                <Button
                  marginTop="default"
                  marginBottom={40}
                  marginRight={16}
                  width={280}
                  marginLeft={40}
                  alignItems="center"
                  justifyContent="center"
                  onClick={this.handleUserInfo}
                  disabled={
                    values.full_name === '' ||
                    values.company === '' ||
                    values.website === '' ||
                    values.stack === '' ||
                    values.why === ''
                  }
                >
                  {isLoading ? <Spinner size={16} /> : 'Get Started'}
                </Button>
              </FormField>
            </Pane>
          </Pane>
        </div>
      </div>
    );
  }
}

export default UserInfo;