import React, { Component } from 'react';
import VideoActions from './VideoActions';
import {
  Table,
  Popover,
  Position,
  Menu,
  SelectMenu,
  Button,
  Heading,
  Text,
  IconButton,
  Icon,
  Checkbox,
  TextDropdownButton,
  TagInput,
  Paragraph,
  Pane,
  Dialog,
  toaster,
  Tooltip,
} from 'evergreen-ui';
import style from './style.css';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const Order = {
  NONE: 'NONE',
  ASC: 'ASC',
  DESC: 'DESC',
};

class VideoHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      orderedColumn: 1,
      ordering: Order.NONE,
      recent: 'DESC',
      column2ShowSize: 'size',
      column2ShowStatus: '',
      selected: [],
      addedTags: [],
      removedTags: [],
      unionTags: [],
      intersectionTags: [],
      removedUnionTags: [],
      selectedTag: [],
      selectedTagNames: '',
      selectedVideosToPrivate: false,
      selectedVideosToRebuild: false,
      selectedVideosToAddTag: false,
      selectedVideosToRemoveTag: false,
      selectedVideosToDelete: false,
    };
  }

  getIconForOrder = order => {
    switch (order) {
      case Order.ASC:
        return 'arrow-up';
      case Order.DESC:
        return 'arrow-down';
      default:
        return 'caret-down';
    }
  };

  renderNameTableHeaderCell = () => {
    return (
      <Table.TextHeaderCell
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex={1.5}
      >
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.OptionsGroup
                title="Order"
                options={[
                  { label: 'Newest', value: Order.DESC },
                  { label: 'Oldest', value: Order.ASC },
                ]}
                selected={this.state.recent}
                onChange={value => {
                  this.setState(
                    {
                      recent: value,
                    },
                    () => {
                      this.props.callbackFilterByRecent(this.state.recent);
                    },
                  );
                  // Close the popover when you select a value.
                  close();
                }}
              />
              <Menu.Divider />
            </Menu>
          )}
        >
          <TextDropdownButton icon="caret-down">Name</TextDropdownButton>
        </Popover>
      </Table.TextHeaderCell>
    );
  }

  renderSizeTableHeaderCell = () => {
    return (
      <Table.HeaderCell
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexBasis={240}
        flexShrink={0}
        flexGrow={0}
      >
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.OptionsGroup
                title="Order"
                options={[
                  { label: 'Ascending', value: Order.ASC },
                  { label: 'Descending', value: Order.DESC },
                ]}
                selected={
                  this.state.orderedColumn === 2 ? this.state.ordering : false
                }
                onChange={value => {
                  this.setState({
                    orderedColumn: 2,
                    ordering: value,
                  });
                  // Close the popover when you select a value.
                  close();
                }}
              />

              <Menu.Divider />

              <Menu.OptionsGroup
                title="Show"
                options={[
                  { label: 'Size', value: '' },
                  { label: 'Original Size', value: 'original_size' },
                  { label: 'Compressed Size', value: 'size' },
                  { label: 'Compression Factor', value: 'factor' },
                ]}
                selected={this.state.column2ShowSize}
                onChange={value => {
                  this.setState({
                    column2ShowSize: value,
                  });
                  // Close the popover when you select a value.
                  close();
                }}
              />
            </Menu>
          )}
        >
          <TextDropdownButton
            icon={
              this.state.orderedColumn === 2
                ? this.getIconForOrder(this.state.ordering)
                : 'caret-down'
            }
          >
            {/* {capitalize(this.state.column2ShowSize)} */}
            Size
          </TextDropdownButton>
        </Popover>
      </Table.HeaderCell>
    );
  };

  renderTagsTableHeaderCell = tags => {
    return (
      <Table.TextHeaderCell display="flex" flex={0.8}>
        <SelectMenu
          isMultiSelect
          title="Filter Tags"
          titleView={({ title, close }) => {
            return (
              <Pane
                display="flex"
                alignItems="center"
                borderBottom="default"
                padding={8}
                // height={headerHeight}
                boxSizing="border-box"
              >
                <Pane flex="1" display="flex" alignItems="center">
                  <Text size={400}>{title}</Text>
                  <Tooltip
                    content="Select tags to filter."
                    position={Position.BOTTOM}
                  >
                    <Icon
                      size={12}
                      marginLeft={4}
                      color="rgb(66, 90, 112)"
                      icon="help"
                    />
                  </Tooltip>
                  <Tooltip
                    content="Clear tag filter"
                    position={Position.BOTTOM}
                  >
                    <Button
                      appearance="minimal"
                      // iconBefore="remove"
                      color="rgb(66, 90, 112)"
                      marginLeft={24}
                      height={28}
                      onClick={() => {
                        this.setState(
                          { selected: [], selectedNames: '' },
                          () => {
                            this.props.callbackFilterByTags(
                              this.state.selected,
                            );
                          },
                        );
                      }}
                    >
                      Clear filter
                    </Button>
                  </Tooltip>
                </Pane>
                <Tooltip content="Close" position={Position.BOTTOM}>
                  <IconButton
                    icon="cross"
                    appearance="minimal"
                    height={24}
                    onClick={close}
                  />
                </Tooltip>
              </Pane>
            );
          }}
          options={tags.map(label => ({ label, value: label }))}
          selected={this.state.selected}
          onSelect={item => {
            const selected = [...this.state.selected, item.value];
            const selectedItems = selected;
            const selectedItemsLength = selectedItems.length;
            let selectedNames = '';
            if (selectedItemsLength === 0) {
              selectedNames = '';
            } else if (selectedItemsLength === 1) {
              selectedNames = selectedItems.toString();
            } else if (selectedItemsLength > 1) {
              selectedNames = selectedItemsLength.toString() + ' selected';
            }
            this.setState({ selected, selectedNames });
            this.props.callbackFilterByTags(selected);
          }}
          onDeselect={item => {
            const deselectedItemIndex = this.state.selected.indexOf(item.value);
            const selectedItems = this.state.selected.filter(
              (_item, i) => i !== deselectedItemIndex,
            );
            const selectedItemsLength = selectedItems.length;
            let selectedNames = '';
            if (selectedItemsLength === 0) {
              selectedNames = '';
            } else if (selectedItemsLength === 1) {
              selectedNames = selectedItems.toString();
            } else if (selectedItemsLength > 1) {
              selectedNames = selectedItemsLength.toString() + ' selected';
            }
            this.setState({ selected: selectedItems, selectedNames });
            this.props.callbackFilterByTags(selectedItems);
          }}
        >
          <Button
            width="max-content"
            appearance="minimal"
            iconAfter="caret-down"
            color="rgb(66, 90, 112)"
            paddingLeft={8}
            paddingRight={8}
          >
            Tags{' '}
            {this.state.selectedNames
              ? `: ${capitalize(this.state.selectedNames)}`
              : ''}
          </Button>
        </SelectMenu>
      </Table.TextHeaderCell>
    );
  };

  renderStatusTableHeaderCell = () => {
    return (
      <Table.TextHeaderCell display="flex" flex={0.8}>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.OptionsGroup
                title="Filter by status"
                options={[
                  { label: 'All Videos', value: '' },
                  { label: 'Uploading', value: 'uploading' },
                  { label: 'Upload Stalled', value: 'stalled' },
                  { label: 'Processing', value: 'processing' },
                  { label: 'Ready', value: 'ready' },
                  { label: 'Errored', value: 'error' },
                ]}
                selected={this.state.column2ShowStatus}
                onChange={value => {
                  this.setState({ column2ShowStatus: value });
                  this.props.callbackFilterByStatus(value);
                  // Close the popover when you select a value.
                  close();
                }}
              />
            </Menu>
          )}
        >
          <TextDropdownButton icon="caret-down">
            Status{' '}
            {this.state.column2ShowStatus
              ? `: ${capitalize(this.state.column2ShowStatus)}`
              : ''}
          </TextDropdownButton>
        </Popover>
      </Table.TextHeaderCell>
    );
  };

  updateVideoList = (selectedVideos, task, isVideoPrivate) => {
  
    selectedVideos.forEach(selectedId => {
      let indexToUpdate = this.props.videos.map(function(video) { return video.id }).indexOf(selectedId);
      if (indexToUpdate > -1){
        switch (task) {
          case 'delete':
            this.props.videos.splice(indexToUpdate, 1);
            break;
          case 'updateTags':
            this.state.selectedTag.forEach(tag => {
              let tagIndex = this.props.videos[indexToUpdate].tags.map(function(t) { return t }).indexOf(tag);
              if(tagIndex === -1) this.props.videos[indexToUpdate].tags.push(tag);
            });
            this.state.removedUnionTags.forEach(tag => {
              this.props.videos[indexToUpdate].tags = this.props.videos[indexToUpdate].tags.filter(t => t !== tag)
            });
            break;
          case 'rebuild':
            this.props.videos[indexToUpdate].status = 'processing';
            break;
          case 'private':
            this.props.videos[indexToUpdate].private = isVideoPrivate;
            break;
          default:
            break;
        }
      }
    });

    this.props.handleSelectAllClick();
  };

  renderPrivate = (selectedVideos) => {
    return (
      <Pane>
        <Dialog
          topOffset="24vmin"
          isShown={this.state.selectedVideosToPrivate}
          title="Update video privacy settings"
          intent="none"
          onCloseComplete={() =>
            this.setState({ selectedVideosToPrivate: false })
          }
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          hasClose={false}
          cancelLabel="Set all Public"
          onCancel={() => {
            VideoActions.privateVideo(selectedVideos, false);
            this.updateVideoList(selectedVideos, 'private', false);
            toaster.notify('Video privacy changed successfully', {
              duration: 1,
              id: `privacy-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToPrivate: false });
            close();
          }}
          confirmLabel="Set all Private"
          onConfirm={() => {
            VideoActions.privateVideo(selectedVideos, true);
            this.updateVideoList(selectedVideos, 'private', true);
            toaster.notify('Video privacy changed successfully', {
              duration: 1,
              id: `privacy-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToPrivate: false });
            close();
          }}
        >
          <Paragraph>
            I understand that setting a video as private will not allow any
            unauthorised access to the video and setting a video as public will
            allow any unauthorised access to the video.
          </Paragraph>
        </Dialog>
      </Pane>
    );
  };

  renderAddTag = (selectedVideos, videoTags, tags) => {
    return (
      <Pane>
        <Dialog
          topOffset="24vmin"
          isShown={this.state.selectedVideosToAddTag}
          title="Update Video Tags"
          intent="success"
          onCloseComplete={() => this.setState({ selectedVideosToAddTag: false })}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          confirmLabel="Save Changes"
          onConfirm={() => {
            VideoActions.updateVideo(
              selectedVideos,
              'tag',
              this.state.selectedTag,
              videoTags,
            );
            this.updateVideoList(selectedVideos, 'updateTags');
            toaster.notify('Video tags updated successfully!', {
              duration: 1,
              id: `tag-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToAddTag: false });
          }}
          isConfirmDisabled={
            (this.state.addedTags && this.state.addedTags.length === 0)
          }
        >
          <Pane>
            <Heading padding={5} marginBottom={10} size={200}>
              Add new tags to all the selected videos
            </Heading>
            <SelectMenu
              isMultiSelect
              title="Select tags"
              titleView={({ title, close }) => {
                return (
                  <Pane
                    display="flex"
                    alignItems="center"
                    borderBottom="default"
                    padding={4}
                    boxSizing="border-box"
                  >
                    <Pane flex="1" display="flex" alignItems="center">
                      <Text size={400}>{title}</Text>
                      <Tooltip
                        content="Select tags to add"
                        position={Position.BOTTOM}
                      >
                        <Icon
                          size={12}
                          marginLeft={4}
                          color="rgb(66, 90, 112)"
                          icon="help"
                        />
                      </Tooltip>
                      <Tooltip
                        content="Deselect all tags"
                        position={Position.BOTTOM}
                      >
                        <Button
                          appearance="minimal"
                          // iconBefore="remove"
                          color="rgb(66, 90, 112)"
                          marginLeft={24}
                          height={28}
                          onClick={() => {
                            this.setState({
                              addedTags: [],
                              selectedTag: [],
                              selectedTagNames: '',
                            });
                          }}
                        >
                          Deselect all
                        </Button>
                      </Tooltip>
                    </Pane>
                    <Tooltip content="Close" position={Position.BOTTOM}>
                      <IconButton
                        icon="cross"
                        appearance="minimal"
                        height={24}
                        onClick={close}
                      />
                    </Tooltip>
                  </Pane>
                );
              }}
              options={tags.map(label => ({ label, value: label }))}
              selected={this.state.selectedTag}
              onSelect={item => {
                const selectedTag = [...this.state.selectedTag, item.value];
                const selectedTagItems = selectedTag;
                const selectedTagItemsLength = selectedTagItems.length;
                let selectedTagNames = '';
                if (selectedTagItemsLength === 0) {
                  selectedTagNames = '';
                } else if (selectedTagItemsLength === 1) {
                  selectedTagNames = selectedTagItems.toString();
                } else if (selectedTagItemsLength > 1) {
                  selectedTagNames =
                    selectedTagItemsLength.toString() + ' added';
                }
                let addedTags = [...this.state.addedTags];
                if (addedTags.indexOf(item.value) === -1) addedTags.push(item.value);
                this.setState({
                  addedTags: addedTags,
                  selectedTag,
                  selectedTagNames,
                });
              }}
              onDeselect={item => {
                const deselectedTagItemIndex = this.state.selectedTag.indexOf(
                  item.value,
                );
                const selectedTagItems = this.state.selectedTag.filter(
                  (_item, i) => i !== deselectedTagItemIndex,
                );
                const selectedTagItemsLength = selectedTagItems.length;
                let selectedTagNames = '';
                if (selectedTagItemsLength === 0) {
                  selectedTagNames = '';
                } else if (selectedTagItemsLength === 1) {
                  selectedTagNames = selectedTagItems.toString();
                } else if (selectedTagItemsLength > 1) {
                  selectedTagNames =
                    selectedTagItemsLength.toString() + ' added';
                }
                let addedTags = [...this.state.addedTags];
                if (addedTags.indexOf(item.value) !== -1) addedTags.splice(addedTags.indexOf(item.value));
                this.setState({
                  addedTags: addedTags,
                  selectedTag: selectedTagItems,
                  selectedTagNames,
                });
              }}
            >
              <Button iconAfter="caret-down">
                Tags
                {/* {this.state.selectedTagNames ? `: ${capitalize(this.state.selectedTagNames)}`: ''} */}
              </Button>
            </SelectMenu>
            <TagInput
              width="80%"
              float="right"
              tagProps={value => {
                // if (this.state.addedTags.includes(value))
                //   return { color: 'red', isSolid: true };
                return {};
              }}
              inputProps={{ placeholder: 'Type to add a tag' }}
              values={this.state.selectedTag}
              onAdd={addedTags => {
                if (addedTags && addedTags.length !== 0 && this.state.unionTags.concat(this.state.removedUnionTags).indexOf(addedTags[0]) === -1) this.setState({ addedTags: addedTags });
                if (addedTags && addedTags.length !== 0 && this.state.selectedTag.indexOf(addedTags[0]) === -1 && this.state.unionTags.concat(this.state.removedUnionTags).indexOf(addedTags[0]) === -1) {
                  let selectedTag = [...this.state.selectedTag];
                  selectedTag.push(addedTags[0]);
                  this.setState({ selectedTag: selectedTag });
                }
              }}
              onRemove={removedTags => {
                if (removedTags && removedTags.length !== 0) this.setState({ removedTags: removedTags });
                if (removedTags && removedTags.length !== 0 && this.state.selectedTag.indexOf(removedTags) !== -1 && this.state.unionTags.concat(this.state.removedUnionTags).indexOf(removedTags) === -1) {
                  let selectedTag = [...this.state.selectedTag];
                  selectedTag.splice(selectedTag.indexOf(removedTags));
                  this.setState({ selectedTag: selectedTag });
                }
              }}
              onChange={selectedTag => {
                // if (this.state.selectedTag.indexOf(selectedTag[selectedTag.length - 1]) === -1 && this.state.unionTags.concat(this.state.removedUnionTags).indexOf(selectedTag[selectedTag.length - 1]) === -1) this.setState({ selectedTag: selectedTag });
              }}
            />
          </Pane>
        </Dialog>
      </Pane>
    );
  };

  renderRemoveTag = (selectedVideos, videoTags, tags) => {
    return (
      <Pane>
        <Dialog
          topOffset="24vmin"
          isShown={this.state.selectedVideosToRemoveTag}
          title="Update Video Tags"
          intent="success"
          onCloseComplete={() => this.setState({ selectedVideosToRemoveTag: false })}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          confirmLabel="Save Changes"
          onConfirm={() => {
            VideoActions.updateVideo(
              selectedVideos,
              'tag',
              this.state.selectedTag,
              videoTags,
              this.state.removedUnionTags,
            );
            this.updateVideoList(selectedVideos, 'updateTags');
            toaster.notify('Video tags updated successfully!', {
              duration: 1,
              id: `tag-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToRemoveTag: false });
          }}
          isConfirmDisabled={
            (this.state.removedTags && this.state.removedTags.length === 0) &&
            (this.state.removedUnionTags && this.state.removedUnionTags.length === 0)
          }
        >
          <Pane>
            <Heading
              padding={5}
              marginBottom={10}
              size={200}
            >
              Remove existing tags from all the selected videos
            </Heading>
            <TagInput
              width="100%"
              float="right"
              marginBottom="default"
              tagProps={value => {
                // if (this.state.addedTags.includes(value))
                //   return { color: 'red', isSolid: true };
                return {};
              }}
              inputProps={{ placeholder: 'Remove tag' }}
              values={this.state.unionTags}
              // onAdd={addedTags => {
              //   // this.setState({ addedTags: addedTags });
              // }}
              onRemove={removedTags => {
                if (removedTags && removedTags.length !== 0) {
                  let tempUnionTags = [...this.state.unionTags];
                  tempUnionTags = tempUnionTags.filter(
                    tag => tag !== removedTags,
                  );
                  let tempRemoveUnionTags = [...this.state.removedUnionTags];
                  if (tempRemoveUnionTags.indexOf(removedTags) === -1) tempRemoveUnionTags.push(removedTags);
                  this.setState({
                    unionTags: tempUnionTags,
                    removedUnionTags: tempRemoveUnionTags,
                  });
                }
              }}
            // onChange={selectedTag => {
            //   this.setState({ selectedTag: selectedTag });
            // }}
            />
          </Pane>
        </Dialog>
      </Pane>
    );
  };

  renderRebuild = (selectedVideos) => {
    return (
      <Pane>
        <Dialog
          topOffset="24vmin"
          isShown={this.state.selectedVideosToRebuild}
          title="Are you sure you want to rebuild this video?"
          intent="success"
          onCloseComplete={() =>
            this.setState({ selectedVideosToRebuild: false })
          }
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          confirmLabel="Rebuild"
          onConfirm={() => {
            VideoActions.rebuildVideo(selectedVideos);
            this.updateVideoList(selectedVideos, 'rebuild');
            toaster.notify('Rebuilding Video...', {
              duration: 1,
              id: `rebuild-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToRebuild: false });
          }}
        >
          <Paragraph>
            I understand that rebuilding a video from Vectorly is permanent
            going to remove earlier builds and cannot be undone.
          </Paragraph>
        </Dialog>
      </Pane>
    );
  };

  renderDelete = (selectedVideos, numSelected, rowCount) => {
    return (
      <Pane>
        <Dialog
          topOffset="24vmin"
          isShown={this.state.selectedVideosToDelete}
          title="Permanently delete this video?"
          intent="danger"
          onCloseComplete={() =>
            this.setState({ selectedVideosToDelete: false })
          }
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEscapePress={true}
          confirmLabel="Delete"
          onConfirm={() => {
            VideoActions.deleteVideo(selectedVideos);
            this.updateVideoList(selectedVideos, 'delete');
            toaster.notify('Video deleted', {
              duration: 1,
              id: `delete-code-${selectedVideos}`,
            });
            this.setState({ selectedVideosToDelete: false });
            this.props.reloadList(numSelected === rowCount);
          }}
        >
          <Paragraph>
            I understand that deleting a video from Vectorly is permanent and
            cannot be undone.
          </Paragraph>
        </Dialog>
      </Pane>
    );
  };

  renderActionRowMenu = () => {
    return (
      <Menu>
        <Menu.Group>
          <Menu.Item
            className={style.textColor}
            icon="tag"
            onSelect={() => {
              let unionTags = [], intersectionTags = [], tagPresent = 0;
              let selectedVideos = this.props.videos.filter(video =>
                this.props.selectedVideos.includes(video.id),
              );
              let tags = this.props.tags;
              intersectionTags = tags.filter(tag => {
                tagPresent = 0;
                for (let index = 0; index < selectedVideos.length; index++) {
                  if(selectedVideos[index].tags.indexOf(tag) !== -1) {
                    tagPresent++;
                    if (index === selectedVideos.length - 1) {
                      if (tagPresent === selectedVideos.length) return true;
                      else return false;
                    }
                  }
                }
              })
              unionTags = selectedVideos.map(video => video.tags);
              unionTags = [].concat(...unionTags);
              unionTags = Array.from(new Set(unionTags));
              this.setState({
                selectedVideosToAddTag: true,
                intersectionTags: intersectionTags, //selectedTag
                selectedTag: [],
                unionTags: unionTags,
                removedUnionTags: [],
                addedTags: [],
                removedTags: [],
              });
            }}
          >
            Add Tags
          </Menu.Item>
          <Menu.Item
            className={style.textColor}
            icon="tag"
            onSelect={() => {
              let unionTags = [], intersectionTags = [], tagPresent = 0;
              let selectedVideos = this.props.videos.filter(video =>
                this.props.selectedVideos.includes(video.id),
              );
              let tags = this.props.tags;
              intersectionTags = tags.filter(tag => {
                tagPresent = 0;
                for (let index = 0; index < selectedVideos.length; index++) {
                  if (selectedVideos[index].tags.indexOf(tag) !== -1) {
                    tagPresent++;
                    if (index === selectedVideos.length - 1) {
                      if (tagPresent === selectedVideos.length) return true;
                      else return false;
                    }
                  }
                }
              })
              unionTags = selectedVideos.map(video => video.tags);
              unionTags = [].concat(...unionTags);
              unionTags = Array.from(new Set(unionTags));
              this.setState({
                selectedVideosToRemoveTag: true,
                intersectionTags: intersectionTags, //selectedTag
                selectedTag: [],
                unionTags: unionTags,
                removedUnionTags: [],
                addedTags: [],
                removedTags: [],
              });
            }}
          >
            Remove Tags
          </Menu.Item>
          <Menu.Item
            className={style.textColor}
            icon="lock"
            onSelect={() => this.setState({ selectedVideosToPrivate: true })}
          >
            Change Privacy
          </Menu.Item>
          {process.env.ENV !== 'prod' ? (
            <Menu.Item
              className={style.textColor}
              icon="redo"
              onSelect={() => {
                this.setState({ selectedVideosToRebuild: true });
              }}
            >
              Rebuild All
            </Menu.Item>
          ) : (
            ''
          )}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item
            className={style.textColor}
            intent="danger"
            icon="trash"
            onSelect={() => {
              this.setState({ selectedVideosToDelete: true });
            }}
          >
            Delete All
          </Menu.Item>
        </Menu.Group>
      </Menu>
    );
  };

  render() {
    const {
      selectedVideos,
      tags,
      numSelected,
      rowCount,
      handleSelectAllClick,
    } = this.props;
    return (
      <Table.Head
        height={40}
        paddingX={32}
        paddingY={5}
        className={style.overflow}
      >
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={numSelected === rowCount && rowCount !== 0}
          display="flex"
          alignItems="center"
          width={36}
          flex={0.1}
          onChange={event => handleSelectAllClick(event)}
        />
        <Table.TextHeaderCell
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={style.textCenter}
          flex={1}
        >
          Video
        </Table.TextHeaderCell>
        {this.renderNameTableHeaderCell()}
        {this.renderTagsTableHeaderCell(tags)}
        {this.renderStatusTableHeaderCell()}
        <Table.TextHeaderCell
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          Size
        </Table.TextHeaderCell>
        <Table.TextHeaderCell
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          {this.renderPrivate(selectedVideos)}
          {this.renderRebuild(selectedVideos)}
          {this.renderAddTag(selectedVideos, [], tags)}
          {this.renderRemoveTag(selectedVideos, [], tags)}
          {this.renderDelete(selectedVideos, numSelected, rowCount)}
          <Popover
            content={this.renderActionRowMenu}
            position={Position.BOTTOM_RIGHT}
            isShown={numSelected > 0}
          >
            <TextDropdownButton height={30} icon="caret-down">
              Actions
            </TextDropdownButton>
          </Popover>
        </Table.TextHeaderCell>
      </Table.Head>
    );
  }
}

export default VideoHeader;