import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Pane,
  Text,
  FormField,
  TextInputField,
  Button,
  Heading,
  Spinner,
} from 'evergreen-ui';
import axios from 'axios';
import style from './style.css';
import logo from '../../../../assets/vectorly-full.svg';
import earth from '../../../../assets/earth-vector.png';

const API_URL = process.env.API_URL;

const sendResetLink = values => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(`${API_URL}/auth/forgot`, {
          email: values.email,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

class Reset extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        email: '',
      },
      errors: {
        email: null,
      },
      isValid: false,
      isLoading: false,
      submitSuccess: false,
      submitError: null,
    };
  }

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    // TODO Add validations
    // const errors = validate(values, schema);

    // newState.errors = errors || {};
    // newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleForgot = async () => {
    try {
      const { history } = this.props;
      const { values } = this.state;

      this.setState({ isLoading: true });

      const response = await sendResetLink({
        email: values.email,
      });

      if (response && response.status === 200) {
        this.setState({
          isLoading: false,
          submitSuccess: true,
        });
      } else {
        throw response;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        submitError: error,
      });
    }
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { values, submitSuccess, submitError, isLoading } = this.state;

    return (
      <div className={style.welcome}>
        <Pane padding={25}>
          <img src={logo} alt="Logo" className={style.logoStyle} />
          <Text className={style.loginText}>
            Already have an account?{' '}
            <Link to="/login" className={style.colorWhite}>
              Login
            </Link>
          </Text>
        </Pane>
        <img src={earth} className={style.bg_earth}></img>
        <div className={style.container}>
          <Pane clearfix>
            <Pane
              paddingTop={36}
              paddingBottom={36}
              float="center"
              display="flex"
              justifyContent="center"
              border="none"
            >
              <FormField className={style.formfield}>
                <Heading
                  marginBottom={24}
                  size={400}
                  marginTop={40}
                  marginLeft={60}
                >
                  Verify the email for your account.
                </Heading>
                <TextInputField
                  height={24}
                  marginRight={36}
                  marginBottom={48}
                  marginTop={40}
                  marginLeft={40}
                  width={280}
                  label="Work Email"
                  value={values.email}
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                />
                <div className={style.error}>
                  {submitError ? <Text>{submitError}</Text> : ''}
                </div>
                <Button
                  marginRight={16}
                  marginBottom={16}
                  marginTop="default"
                  marginLeft={40}
                  marginBottom={40}
                  width={280}
                  alignItems="center"
                  justifyContent="center"
                  onClick={this.handleForgot}
                  disabled={values.email === ''}
                >
                  {isLoading ? <Spinner size={16} /> : 'Send Reset Link'}
                </Button>
                <br />
                <div className={style.success}>
                  {submitSuccess ? (
                    <Text color="green">
                      A reset password link was sent to your email address.
                    </Text>
                  ) : (
                    ''
                  )}
                </div>
              </FormField>
            </Pane>
          </Pane>
        </div>
      </div>
    );
  }
}

export default Reset;
