import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader = props => {
  let height, width
  const random = Math.random() * (1 - 0.7) + 0.7;

  switch (props.screen) {
    case 'mobile': {
      height = '80'
      width = '400'
      break
    }
    case 'desktop': {
      height = '44'
      width = '1060'
      break
    }
    case 'large-screen': {
      height = '150'
      width = '1920'
      break
    }
    default: {
      height = '60'
      width = '1060'
      break
    }
  }
  return (
    <ContentLoader
      height={height}
      width={width}
      speed={2}
      primaryColor="#d9d9d9"
      // secondaryColor="#ecebeb"
      // primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="25" y="20" rx="2" ry="2" width="12" height="12" />
      <rect x="90" y="10" rx="2" ry="2" width="64" height="48" />
      <rect x="210" y="20" rx="2" ry="2" width="180" height="12" />
      {/* <rect x="495" y="20" rx="2" ry="2" width="60" height="12" /> */}
      {/* <rect x="435" y="20" rx="2" ry="2" width="180" height="12" /> */}
      <rect x="450" y="20" rx="2" ry="2" width={64} height="12" />
      <rect x="580" y="20" rx="2" ry="2" width={64} height="12" />
      <rect x="710" y="20" rx="2" ry="2" width={40} height="12" />
      <rect x="765" y="20" rx="2" ry="2" width={12} height="12" />
      <rect x="788" y="20" rx="2" ry="2" width={40} height="12" />
      <rect x="880" y="20" rx="2" ry="2" width={84} height="12" />
    </ContentLoader>
  );
}


const ThumbnailLoader = props => {
  let height, width;

  switch (props.screen) {
    case 'mobile': {
      height = '80';
      width = '400';
      break;
    }
    case 'desktop': {
      height = '56';
      width = '96';
      break;
    }
    case 'large-screen': {
      height = '150';
      width = '1920';
      break;
    }
    default: {
      height = '60';
      width = '1060';
      break;
    }
  }
  return (
    <ContentLoader
      height={height}
      width={width}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="42" y="0" rx="2" ry="2" width="96" height="56" />
    </ContentLoader>
  );
};

const ThumbnailLoading = () => (
  <React.Fragment>
    {Array(1)
      .fill('')
      .map((e, i) => (
        <ThumbnailLoader
          screen="desktop"
          key={i}
          style={
            ({ opacity: Number(2 / i).toFixed(1) },
            { width: '112px', height: '44px' })
          }
        />
      ))}
  </React.Fragment>
);

const TableLoading = () => (
  <React.Fragment>
    {Array(10)
      .fill('')
      .map((e, i) => (
        <Loader
          screen="desktop"
          key={i}
          style={{ opacity: Number(2 / i).toFixed(1) }}
        />
      ))}
  </React.Fragment>
);

export { TableLoading, ThumbnailLoading };
