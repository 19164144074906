import React, { Component } from 'react';
import Img from 'react-image';
import VideoActions from './VideoActions';
import style from './style.css';
import uploading from '../../../../../../assets/video_uploading.png';
import { ThumbnailLoading } from './PlaceholderLoading';
import {
  Table,
  Popover,
  Position,
  Menu,
  Button,
  Text,
  IconButton,
  Icon,
  Checkbox,
  Badge,
  Pane,
  Tooltip,
} from 'evergreen-ui';

const thumbnailURL = process.env.STREAM_URL + '/file/thumbnail/';

const canPlay = VideoActions.canPlay

const Thumbnail = props => (
  <Img
    src={[`${thumbnailURL}${props.videoId}`, uploading]}
    loader={<ThumbnailLoading />}
    unloader={uploading}
    className={style.thumbnail}
    onClick={() => props.playCallback()}
  />
);

const DefaultThumbnail = () => (
  <Img
    src={[uploading]}
    loader={<ThumbnailLoading />}
    unloader={uploading}
    className={style.default_thumbnail}
  />
);

class VideoRowBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVideoToUpdate: null,
    };
  }

  shouldComponentUpdate = nextProps => {
    if (
      this.props.video.id === this.state.selectedVideoToUpdate &&
      this.props.video.id === this.props.selectedVideoToUpdate &&
      nextProps.selectedVideoToUpdate === null
    ) {
      return true;
    } else {
      return (
        JSON.stringify(this.props.video) !== JSON.stringify(nextProps.video) ||
        JSON.stringify(this.props.isSelected) !== JSON.stringify(nextProps.isSelected)
      );
    }
  };

  renderEmbedButton = () => {
    let { video, callbackState, isSelected } = this.props;
    return (
      <Button
        height={30}
        iconBefore="code"
        disabled={!canPlay(video) || isSelected}
        onClick={() => callbackState({ selectedVideoToEmbed: video })}
      >
        Embed
      </Button>
    );
  };

  renderPlayButton = () => {
    let { video, callbackState } = this.props;
    return canPlay(video) ? (
      <Menu.Item
        className={style.textColor}
        icon="play"
        onSelect={() => callbackState({ selectedVideoToPlay: video })}
      >
        Preview
      </Menu.Item>
    ) : null;
  };

  renderTagButton = () => {
    let { video, callbackState } = this.props;
    return (
      <Menu.Item
        className={style.textColor}
        icon="tag"
        onSelect={() => {
          this.setState({ selectedVideoToUpdate: video.id }, () =>
            callbackState({
              selectedVideoToTag: video,
              selectedVideoToUpdate: video.id,
              selectedTag: video.tags,
              addedTags: [],
              removedTags: [],
            }),
          );
        }}
      >
        Update Tag
      </Menu.Item>
    );
  };

  renderPrivacyButton = () => {
    let { video, callbackState } = this.props;
    return (
      <Menu.Item
        className={style.textColor}
        icon={video.private ? 'eye-off' : 'eye-open'}
        onSelect={() => {
          this.setState({ selectedVideoToUpdate: video.id }, () =>
            callbackState({
              selectedVideoToPrivate: video,
              selectedVideoToUpdate: video.id,
            }),
          );
          }
        }
      >
        Change Privacy
      </Menu.Item>
    );
  };

  renderRenameButton = () => {
    let { video, callbackState } = this.props;
    return (
      <Menu.Item
        className={style.textColor}
        icon="edit"
        onSelect={() => {
          this.setState({ selectedVideoToUpdate: video.id }, () =>
            callbackState({
              selectedVideoToRename: video.id,
              selectedVideoToUpdate: video.id,
              changedName: video.name,
            })
          );
        }}
      >
        Rename
      </Menu.Item>
    );
  };

  renderRebuildButton = () => {
    let { video, callbackState } = this.props;
    return ['ready', 'processing', 'error'].indexOf(video.status) !== -1 &&
      process.env.ENV !== 'prod' ? (
      <Menu.Item
        className={style.textColor}
        icon="redo"
        onSelect={() => {
          this.setState({ selectedVideoToUpdate: video.id }, () =>
            callbackState({
              selectedVideoToRebuild: video.id,
              selectedVideoToUpdate: video.id,
            })
          );
        }}
      >
        Rebuild
      </Menu.Item>
    ) : null;
  };

  renderDownloadButton = () => {
    let { video, callbackState } = this.props;
    return video.status === 'ready' ? (
      <Menu.Item
        className={style.textColor}
        icon="download"
        onSelect={() => callbackState({ selectedVideoToDownload: video })}
      >
        Download
      </Menu.Item>
    ) : null;
  };

  renderDeleteButton = () => {
    let { video, callbackState } = this.props;
    return (
      <Menu.Item
        className={style.textColor}
        intent="danger"
        icon="trash"
        onSelect={() => {
          this.setState({ selectedVideoToUpdate: video.id }, () =>
            callbackState({
              selectedVideoToDelete: video.id,
              selectedVideoToUpdate: video.id,
            })
          );
        }}
      >
        Delete
      </Menu.Item>
    );
  };

  renderTagBadges = tag => {
    return (
      <Badge color="neutral" marginTop="default">
        {tag}
      </Badge>
    );
  };

  renderRowMenu = () => {
    return (
      <Menu>
        <Menu.Group>
          {this.renderPlayButton()}
          {this.renderTagButton()}
          {this.renderPrivacyButton()}
          {this.renderRenameButton()}
          {this.renderRebuildButton()}
          {this.renderDownloadButton()}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>{this.renderDeleteButton()}</Menu.Group>
      </Menu>
    );
  };

  render() {
    const { video, handleSelection, isSelected, callbackState } = this.props;
    return (
      <Table.Row
        key={video.id}
        height={65}
        paddingX={32}
        paddingY={4}
        isSelectable
        className={style.cursor}
      >
        <Checkbox
          checked={isSelected}
          display="flex"
          alignItems="center"
          width={36}
          flex={0.1}
          onChange={event => handleSelection(event, video.id)}
        />
        <Table.Cell display="flex" alignItems="center" flex={1}>
          {canPlay(video) ? (
            <Thumbnail
              videoId={video.id}
              playCallback={() => callbackState({ selectedVideoToPlay: video })}
            />
          ) : (
            <DefaultThumbnail />
          )}
        </Table.Cell>
        <Table.Cell display="flex" alignItems="center" flex={1.5}>
          {video.private ? (
            <div className={style.marginRight}>
              <Tooltip content="Private" position={Position.BOTTOM}>
                <Icon className={style.privacyIcon} icon="eye-off" />
              </Tooltip>
              {/* <Tooltip content="Public" position={Position.RIGHT}>
                <Icon className={style.privacyIcon} icon="eye-open" />
              </Tooltip> */}
            </div>
          ) : null
          }
          <Tooltip content={video.name} position={Position.BOTTOM}>
            <Text size={300} fontWeight={500} className={style.videoName}>
              {video.name}
            </Text>
          </Tooltip>
        </Table.Cell>
        <Table.Cell display="flex" alignItems="center" flex={0.8}>
          {video.tags.length > 0 ? (
            <Badge color="neutral">{video.tags[0]}</Badge>
          ) : (
            ''
          )}
          {video.tags.length > 1 ? (
            <Popover
              content={
                <Pane
                  width="auto"
                  height="auto"
                  padding={16}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  {video.tags.map(tag => this.renderTagBadges(tag))}
                </Pane>
              }
            >
              <Tooltip content="Show all tags..." position={Position.RIGHT}>
                <IconButton
                  appearance="minimal"
                  color="rgb(66, 90, 112)"
                  height={30}
                  icon="more"
                />
              </Tooltip>
            </Popover>
          ) : (
            ''
          )}
        </Table.Cell>
        <Table.TextCell display="flex" alignItems="center" flex={0.8}>
          {VideoActions.renderRowStatus(video.status)}
        </Table.TextCell>
        <Table.Cell display="flex" alignItems="center" flex={1}>
          <Tooltip content="Original" position={Position.BOTTOM}>
            <Text flexBasis={60} size={300} fontWeight={500}>
              {VideoActions.getReadableFileSizeString(video.original_size)}
            </Text>
          </Tooltip>
          <Icon flexBasis={20} size={12} icon="arrow-right" color="muted" />
          {video.size ? (
            <Tooltip content="Compressed" position={Position.BOTTOM}>
              <Text flexBasis={60} marginLeft={10} size={300} fontWeight={500}>
                {VideoActions.getReadableFileSizeString(video.size)}
              </Text>
            </Tooltip>
          ) : (
            <Tooltip content="In Progress..." position={Position.BOTTOM}>
              <Text flexBasis={60} marginLeft={10} size={300} fontWeight={500}>
                ...
              </Text>
            </Tooltip>
          )}
        </Table.Cell>
        <Table.Cell display="flex" alignItems="center" flex={1}>
          {this.renderEmbedButton()}
          <Popover
            content={this.renderRowMenu()}
            position={Position.BOTTOM_RIGHT}
          >
            <IconButton icon="caret-down" height={30} disabled={isSelected} />
          </Popover>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default VideoRowBase;
