import axios from 'axios';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import VideoTable from './components/Table/VideoTable';
import VideoHeader from './components/Table/VideoHeader';
const API_URL = process.env.API_URL;
import {
  Pane,
  Table,
  IconButton,
  Button,
  Spinner,
  Text,
  Tooltip,
  Position,
  Popover,
  Menu,
  TextDropdownButton,
} from 'evergreen-ui';
import style from './style.css';

let timeout;

const fetchVideos = filter => {
  let url = filter
    ? API_URL + '/videos/list' + filter
    : API_URL + '/videos/list?filter[limit]=10&filter[skip]=0&filter[order][0]=created_at%20DESC';
  return new Promise(resolve => {
    // Make a new timeout set to go off in 250ms
    setTimeout(() => {
      axios
        .post(url, {
          client_id: JSON.parse(localStorage.client).id,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.message);
        });
    }, 10);
  });
};

const fetchTags = () => {
  let url = API_URL + '/videos/tags';
  return new Promise(resolve => {
      axios
        .post(url, {
          client_id: JSON.parse(localStorage.client).id,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.message);
        });
  });
};

class Library extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
      tags: [],
      pageCount: 0,
      forcePage: 0,
      limit: 10,
      skip: 0,
      total: 0,
      filteredCount: 0,
      isLoading: false,
      created_at: 'DESC',
      searchString: '',
      selected: [],
      statusToFilter: '',
      tagsToFilter: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetchVideos().then(response => {
      this.setState({
        videos: response.data.videos,
        total: response.data.total,
        filteredCount: response.data.filteredCount,
        pageCount: Math.ceil(response.data.total / this.state.limit),
        pageArray: Array.from({ length: Math.ceil(response.data.total / this.state.limit) }, (v, i) => i+1),
        isLoading: false,
      });
    });
    fetchTags().then(response => {
      this.setState({ tags: response.data });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString) {
      this.reloadFromSearch()
    }
    if (prevProps.reload !== this.props.reload && this.props.reload === true) {
      setTimeout(() => {
        this.reloadList();
      }, 10);
    }
  }


  reloadFromSearch = () => {

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.setState({ forcePage: 0, skip: 0, pageCount: 0 });
      this.reloadList();
    }, 250);

  }

  reloadList = (multiDelete) => {
    
    let filter = '', skipDeleted;

    if (this.state.limit) filter += `?filter[limit]=${this.state.limit}`;
    if (multiDelete) {
        skipDeleted = (this.state.skip - this.state.limit) > 0 ? (this.state.skip - this.state.limit) : 0;
        filter += `&filter[skip]=${skipDeleted}`;
    } else {
      if (this.state.skip) filter += `&filter[skip]=${this.state.skip}`;
    }
    if(this.state.created_at) filter += `&filter[order][0]=created_at%20${this.state.created_at}`;
    if (this.props.searchString)
      filter += `&filter[name]=${this.props.searchString}`;
    if (this.state.tagsToFilter)
      filter += `&filter[tags]=${this.state.tagsToFilter}`;
    if (this.state.statusToFilter) filter += `&filter[status]=${this.state.statusToFilter}`;
    if (this.state.private) filter += `&filter[private]=${this.state.private}`;
    if (this.state.orderByName) filter += `&filter[order][0]=name%20ASC`;
    if (this.state.orderByOriginalSize)
      filter += `filter[order][0]=original_size%20ASC`;
    if (this.state.orderByCompressedSize)
      filter += `&filter[order][1]=size%20DESC`;

    this.setState({
      isLoading: true,
      selected: [],
    });

    fetchVideos(filter).then(response => {
      this.setState({
        videos: response.data.videos,
        total: response.data.total,
        filteredCount: response.data.filteredCount,
        pageCount: Math.ceil(response.data.filteredCount / this.state.limit),
        pageArray: Array.from({ length: Math.ceil(response.data.filteredCount / this.state.limit) }, (v, i) => i+1),
        isLoading: false,
      });
      if(multiDelete){
        this.setState({ skip: skipDeleted, forcePage: this.state.forcePage - 1 });
      }
    });

  };

  // call when a new tag is created
  // fetchTags().then(response => {
  //   this.setState({ tags: response.data });
  // });

  filterByRecent = createdAt => {
    if (createdAt !== this.state.created_at) {
      this.setState(
        { created_at: createdAt, forcePage: 0, skip: 0, pageCount: 0 },
        () => {
          this.reloadList();
        },
      );
    }
  }

  filterByTags = tags => {
    let firstFilter = tags.length === 0 && this.state.tagsToFilter.length === 0;
    if (tags !== this.state.tagsToFilter && !firstFilter) {
      this.setState(
        { tagsToFilter: tags, forcePage: 0, skip: 0, pageCount: 0 },
        () => {
          this.reloadList();
        },
      );
    }
  };

  filterByStatus = status => {
    if (status !== this.state.statusToFilter) {
      this.setState(
        { statusToFilter: status, forcePage: 0, skip: 0, pageCount: 0 },
        () => {
          this.reloadList();
        },
      );
    }
  };

  handlePageClick = videos => {
    let selected = videos.selected;
    let skip = Math.ceil(selected * this.state.limit);
    this.setState(
      {
        skip: skip,
        forcePage: selected,
        selected: [],
      },
      () => {
        this.reloadList();
      },
    );
  };

  changeRowsPerPage = limit => {
    if (limit !== this.state.limit) {
      this.setState(
        { limit: limit, forcePage: 0, skip: 0, pageCount: 0 },
        () => {
          this.reloadList();
        },
      );
    }
  };

  goToPage = (page) => {
    let skip = Math.ceil((page-1) * this.state.limit);
    this.setState(
      {
        skip: skip,
        forcePage: page-1,
        selected: [],
      },
      () => {
        this.reloadList();
      },
    );
  }

  setSelected(newSelected) {
    this.setState({ selected: newSelected });
  }

  handleSelection = (event, videoId) => {
    const selected = this.state.selected;
    const selectedIndex = selected.indexOf(videoId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, videoId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setSelected(newSelected);
  };

  handleSelectAllClick = event => {
    if (event && event.target.checked && !this.state.isLoading) {
      const newSelecteds = this.state.videos.map(video => video.id);
      this.setSelected(newSelecteds);
      return;
    } else {
      this.setSelected([]);
    }
  };

  render() {
    const {
      videos,
      tags,
      isLoading,
      selected,
      limit,
      skip,
      total,
      filteredCount,
      forcePage,
      pageCount,
      pageArray,
    } = this.state;
    return (
      <div>
        <Pane className={style.table_rows_body}>
          <Pane className={style.table_body}>
            <Text size={500} marginLeft={30}>
                Videos
            </Text>
            {isLoading ? (
              <Tooltip content="Loading..." position={Position.RIGHT}>
                <Button marginTop={-5} marginLeft={22}>
                  <Spinner size={16} />
                </Button>
              </Tooltip>
            ) : (
                <Tooltip
                  content="Reload to view latest changes"
                  position={Position.RIGHT}
                >
                  <IconButton
                    marginTop={-25}
                    marginLeft={100}
                    alignItems="center"
                    justifyContent="center"
                    width={50}
                    icon="repeat"
                    onClick={() => this.reloadList()}
                  />
                </Tooltip>
              )}
            {(this.state.tagsToFilter.length !== 0 || this.state.statusToFilter !== '' || this.props.searchString !== '') && filteredCount > 0 && !isLoading ? (
              <Text size={300} marginLeft={30} className={style.filtertext}>
                Filtered {filteredCount} videos of {total}
              </Text>
            ) : null}
            {(this.state.tagsToFilter.length === 0 && this.state.statusToFilter === '' && this.props.searchString === '') && filteredCount === total && !isLoading ? (
              <Text size={300} marginLeft={30} className={style.filtertext}>
                Showing {videos.length} videos of {total}
              </Text>
            ) : null}
          </Pane>
          <Table className={style.table}>
            <VideoHeader
              videos={videos}
              tags={tags}
              selectedVideos={selected}
              numSelected={selected.length}
              rowCount={videos.length}
              reloadList={this.reloadList}
              handleSelectAllClick={this.handleSelectAllClick}
              callbackFilterByRecent={this.filterByRecent}
              callbackFilterByTags={this.filterByTags}
              callbackFilterByStatus={this.filterByStatus}
            />
            <VideoTable
              videos={videos}
              tags={tags}
              isLoading={isLoading}
              selected={selected}
              handleSelection={this.handleSelection}
            />
          </Table>
          {isLoading === false && (skip !== 0 || pageCount >= 1) ? (
            <div className={style.pagination}>
              <Popover
                position={Position.BOTTOM_LEFT}
                content={({ close }) => (
                  <Menu>
                    <Menu.OptionsGroup
                      title="Select limit"
                      options={[
                        { label: '10', value: 10 },
                        { label: '30', value: 30 },
                        { label: '50', value: 50 },
                      ]}
                      selected={limit}
                      onChange={value => {
                        this.setState({ limit: value });
                        this.changeRowsPerPage(value);
                        // Close the popover when you select a value.
                        close();
                      }}
                    />
                  </Menu>
                )}
              >
                <TextDropdownButton
                  icon="caret-down"
                  marginRight={16}
                  marginBottom={66}
                >
                  Rows per page
                </TextDropdownButton>
              </Popover>
              <Popover
                position={Position.BOTTOM_LEFT}
                content={({ close }) => (
                  <Menu>
                    <Menu.OptionsGroup
                      title="Goto page"
                      options={pageArray.map(n => ({ label: n, value: n }))}
                      selected={forcePage + 1}
                      onChange={value => {
                        if (value !== forcePage + 1) {
                          this.setState({ forcePage: value });
                          this.goToPage(value);
                        }
                        // Close the popover when you select a value.
                        close();
                      }}
                    />
                  </Menu>
                )}
              >
                <TextDropdownButton
                  icon="caret-down"
                  marginRight={16}
                  marginBottom={66}
                >
                  Page
                </TextDropdownButton>
              </Popover>
              <ReactPaginate
                // initialPage={forcePage}
                forcePage={forcePage}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                activeLinkClassName={style.activeLink}
              />
            </div>
          ) : (
            ''
          )}
        </Pane>
      </div>
    );
  }
}

export default Library;
