import React, { Component } from 'react';
import Navbar from '../../../Navbar/index';
import {
  Pane,
  Heading,
  Paragraph,
  Switch,
  Text,
  IconButton,
  UnorderedList,
  ListItem,
  Code,
  toaster,
  Spinner,
  Button,
} from 'evergreen-ui';
import style from './style.css';
import axios from 'axios';
const API_URL = process.env.API_URL;

const getAccount = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/auth/account', {
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const getModelToken = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/upscaler/model-tokens', {
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const updateAccount = value => {
  return new Promise(resolve => {
    setTimeout(() => {
      axios
        .post(API_URL + '/user/update', {
          data: {default_private: value},
          username: JSON.parse(localStorage.user).username,
          token: JSON.parse(localStorage.user).token,
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          resolve(error.response.data.message);
        });
    }, 100);
  });
};

const copyApiKey = key => {
  let textField = document.createElement('textarea');
  textField.innerText = `${key}`;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};


class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: null,
      defaultPrivate: false,
      modelToken: null,
    };
  }

  componentDidMount() {
    getAccount().then(response => {
      this.setState({
        key: response.data.key,
        token: response.data.token,
        defaultPrivate: response.data.settings.default_private,
      });
    });

    getModelToken().then(response => {
      console.log('getModelToken', response.data, response.data.model_environments)
      let modelEnvs = response.data.model_environments
      this.setState({
        modelToken: modelEnvs[0].token
      });
    });
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  toggleDefaultPrivate = () => {
    let value = this.state.defaultPrivate;
    updateAccount(value).then(response => {
      console.log('response.data', response.data);
      toaster.notify('Privacy settings updated successfully!', {
        duration: 1,
        id: `defaultprivate`
      });
    });
  }

  render() {
    const { key, modelToken, defaultPrivate } = this.state;
    return (
      <div>
        <Navbar hideUpload={true} hideSearch={true} />
        <div className={style.padding}>
          <IconButton icon="arrow-left" onClick={this.handleBack} />
          <Heading size={600} marginLeft={120} marginTop={-25}>
            Account Settings
          </Heading>
          <hr />
          <Pane className={style.paddingLeft}>
            <UnorderedList size={500}>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Billing
                </Heading>
                <Paragraph size={400} marginTop="default">
                  You have a closed beta account. Billing and subscription
                  information will be migrated and updated automatically when
                  the beta ends.
                </Paragraph>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  API Key
                </Heading>
                <Paragraph size={400} marginTop="default">
                  Use this API Key to interact with our API. Our API
                  documentation can be found{' '}
                  <a href="https://vectorly.io/docs/" target="_blank">
                    here
                  </a>
                  . If you need to have your API key regenerated, please{' '}
                  <a href="mailto:team@vectorly.io">contact support</a>.
                </Paragraph>
                <div>
                  <br />
                  {key ? (
                    <div>
                      <Code size={400}>{key}</Code>{' '}
                      <Button
                        appearance="minimal"
                        onClick={() => {
                          copyApiKey(key);
                          toaster.notify('API Key copied to clipboard', {
                            duration: 1,
                            id: `api-key-${key}`,
                          });
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                  ) : (
                    <Spinner size={16} />
                  )}
                </div>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Model Serve Token
                </Heading>
                <Paragraph size={400} marginTop="default">
                  Use this Token to integrate into Vectorly's Web upscaler libraries.
                  The documentation can be found{' '}
                  <a href="https://vectorly.io/docs/docs-page.html#section-2" target="_blank">
                    here
                  </a>
                  . If you need to have your Model Serve Token regenerated, please{' '}
                  <a href="mailto:team@vectorly.io">contact support</a>.
                </Paragraph>
                <div>
                  <br />
                  {key ? (
                    <div>
                      <Code size={400}>{modelToken}</Code>{' '}
                      <Button
                        appearance="minimal"
                        onClick={() => {
                          copyApiKey(modelToken);
                          toaster.notify('Model Serve Token copied to clipboard', {
                            duration: 1,
                            id: `model-serve-token`,
                          });
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                  ) : (
                    <Spinner size={16} />
                  )}
                </div>
              </ListItem>
              <ListItem>
                <Heading size={500} marginTop="default">
                  Settings
                </Heading>
                <div className={style.flex}>
                  <Switch
                    marginTop={14}
                    checked={defaultPrivate}
                    value={defaultPrivate}
                    onChange={event => {
                      this.setState(
                        { defaultPrivate: event.target.checked },
                        () => this.toggleDefaultPrivate(),
                      );
                    }}
                  />
                  <Text size={400} marginTop="default" marginLeft={10}>
                    Set (new) videos uploaded to private by default.
                  </Text>
                </div>
              </ListItem>
            </UnorderedList>
          </Pane>
        </div>
      </div>
    );
  }
}

export default Settings;
